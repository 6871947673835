import React, { memo } from 'react';
import { isLoggedIn, getCurrentUser } from '../../services/auth';
import PrimaryButton from '../shared/button/PrimaryButton';
import heroImage from './img/Hero.png';
import work1Image from './img/work1.png';
import work2Image from './img/work2.png';
import work3Image from './img/work3.png';
import './MainPage.scss';

const MainPage = memo(() => {
  return (
    <div>
      <div className="container-bg">
        <div className="container">
          <div className="row header-container">
            <div className="col-6 slogan-container">
              <p className="title gothic head0">
                Your patients’ favorite online contacts. Now fulfilled by you.
              </p>
              <p className="title gothic head5">
                Never lose a patient to online orders again, ContactsPortal allows your patients to
                place their online orders in-house with your professional guidance.
              </p>
              <div>
                {!isLoggedIn() && (
                  <div className="auth-btn-container">
                    <PrimaryButton label="Patient Login" redirectTo="customer/login" small purple />
                    <PrimaryButton label="Doctor Login" redirectTo="doctor/login" small white />
                  </div>
                )}
              </div>
            </div>
            <div className="col-6">
              <div className="hero-img-container">
                <img alt="#" src={heroImage} className="hero-img"></img>
              </div>
            </div>
          </div>
          <div className="row slogan-section hide-text">
            <p className="title gothic head2">Doctor and Patient Happiness Guaranteed</p>
            <p className="title gothic head3">
              At ContactsPortal we have all the major brands your patients wear and love. Prescribe
              and fulfill your patients' online contacts order right from your office—helping you
              meet your patients' vision and eye health needs.
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row info-section">
          <div className="row slogan-section hide-text">
            <p className="slogan">ABOUT</p>
            <p className="text">
              Provide the same exceptional eye exam service you always have. Prescribe lenses to
              your patients based on their vision correction needs and specific lenses your patients
              desire.
            </p>
          </div>
          <div>
            <p className="slogan">How it works</p>
          </div>
          <div className="img-container">
            <div className="item">
              <img alt="#" src={work1Image} />
              <p className="item-name">Lenses Prescribed</p>
              <p className="title gothic">
                Provide the same exceptional eye exam service you always have. Prescribe lenses to
                your patients based on their vision correction needs and specific lenses your
                patients desire.
              </p>
            </div>
            <div className="item">
              <img alt="#" src={work2Image} />
              <p className="item-name">Lenses Ordered</p>
              <p className="title gothic">
                Effortlessly sign in to ContactsPortal with your patient in-office, place their
                prescribed contact lens order, and even schedule their preferred subscription
                frequency.
              </p>
            </div>
            <div className="item">
              <img alt="#" src={work3Image} />
              <p className="item-name">Lenses Delivered</p>
              <p className="title gothic">
                Easily track and manage your patients' orders. All orders are promptly delivered to
                your patients' doorstep. A designated support team is readily available to help
                should you have any questions.
              </p>
            </div>
          </div>
          <div className="btn-container">
            <PrimaryButton
              label="Continue"
              redirectTo={isLoggedIn() ? `/${getCurrentUser()?.type}/account` : '/doctor/login'}
              purple
              small
            />
          </div>
          <div className="slogan-section">
            <p className="title gothic head2">HAPPINESS GUARANTEED</p>
            <p className="title gothic head3">
              ContactsPortal makes getting your patients' favorite online brands a breeze.
            </p>
            <p className="title gothic head3">
              Your satisfaction, and that of your patients, is our top priority.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
});

export default MainPage;
