import React, { useEffect } from 'react';

import Layout from '../components/layout/Layout';

import '../styles/index.scss';
import MainPage from '../components/mainPage/MainPage';

export default function Home({ location }) {
  useEffect(() => {
    if (window !== 'undefined') {
      const WebFont = require('webfontloader');

      WebFont.load({
        google: {
          families: ['FreightDisp'],
        },
      });
    }
  }, []);

  return (
    <Layout location={location}>
      <MainPage />
    </Layout>
  );
}
